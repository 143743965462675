// Automatically load all translations from locales directory 🔥
export default (supportedLanguages = [], namespaces = []) => {
  const locales = {}

  namespaces.forEach((namespace) => {
    supportedLanguages.forEach((language) => {
      import(`locales/${language}/${namespace}/translation.json`).then((translation) => {
        locales[language] = {
          ...locales[language],
          [namespace]: translation.default,
        }
      })
    })
  })

  return locales
}
