import React from 'react'
import classNames from 'classnames'
import uniqueId from 'lodash/uniqueId'
import { Icon, inputs } from '@akkeramsterdam/whatson-components'
import styles from './ExtendedRadio.module.scss'

const { Input } = inputs

export default ({ className, disabled, label, options, onChange, value }) => {
  const id = uniqueId()

  return (
    <div className={classNames(styles.ExtendedRadioWrapper, {
      [styles.Disabled]: disabled
    }, className)}>
      {label &&
        <label
          className={styles.RadioLabel}
          htmlFor={id}
        >
          {label}
        </label>
      }

      {options.map((option, index) =>
      <div
        className={classNames(styles.RadioOption, {
          [styles.Disabled]: option.disabled,
          [styles.Checked]: option.value === value
        })}
        key={index}
        onClick={() => !disabled && !option.disabled && onChange(option.value)}
      >
        <div className={styles.VisualAndRadio}>
          {option.icon && <Icon name={option.icon} />}

          {option.image && <img src={option.image} />}

          <input
            type="radio"
            checked={option.value === value}
            disabled={disabled || option.disabled}
            onChange={() => onChange(option.value)}
          />
        </div>

        <div className={styles.Label}>{option.label}</div>

        <div className={styles.Description}>{option.description}</div>
      </div>
      )}
    </div>
  )
}
