import { call, delay, takeLatest, put } from 'redux-saga/effects'
import api from 'services/api'
import { CampaignAdapter } from 'adapters/campaign'
import { START_BLANK } from 'ui/components/Page/CampaignModal/AutomationTemplate'
import { types as campaignTypes } from 'ui/components/Page/CampaignModal/NameAndType'
import { getCurrentOrganisation } from 'config/helpers'
import * as actions from 'redux/actions/campaign'
import { NOTIFY } from 'redux/actions/notification'

const campaignAdapter = new CampaignAdapter()

function* createCampaign(action) {
  try {
    const organisation = getCurrentOrganisation()
    const campaign = yield call(api.post, `/campaign/create`, {
      name: action.data.name,
      organisation_id: organisation.id,
    })

    yield put({ type: actions.RECEIVE_CREATE_CAMPAIGN })

    if (action.data.template && action.data.template !== START_BLANK) {
      yield call(api.post, `/campaign/set_flow`, {
        campaign_id: campaign.id,
        create_from_template: true,
        flow: action.data.template,
        organisation_id: organisation.id,
      })
    }

    if (action.data.flow) {
      yield call(api.post, `/campaign/set_flow`, {
        campaign_id: campaign.id,
        flow: { ...action.data.flow },
        organisation_id: organisation.id,
      })
    }

    if (action.data.type === campaignTypes.TYPE_SIMPLE) {
      window.location.href = `/campaigns/${campaign.id}`
    } else if (action.data.type === campaignTypes.TYPE_AUTOMATED) {
      window.location.href = `/campaigns/${campaign.id}/flow`
    }
  } catch (error) {
    yield put({
      type: actions.FAILED_CREATE_CAMPAIGN,
    })
  }
}

function* deleteCampaign(action) {
  try {
    const organisation = getCurrentOrganisation()

    yield call(api.delete, `/campaign/delete`, {
      campaign_id: action.campaignId,
      organisation_id: organisation.id,
    })

    yield put({ type: actions.RECEIVE_DELETE_CAMPAIGN })
    window.location.href = `/campaigns`
  } catch (error) {
    yield put({
      type: actions.FAILED_DELETE_CAMPAIGN,
    })
  }
}

function* duplicateCampaign(action) {
  try {
    yield put({
      type: actions.REQUEST_CREATE_CAMPAIGN,
      data: {
        name: `Duplicate: ${action.name}`,
        ...(action.flow
          ? { flow: campaignAdapter.toApi('setDuplicateCampaignFlow', { ...action.flow }) }
          : {}),
      },
    })
  } catch (error) {
    yield put({ type: actions.FAILED_DUPLICATE_CAMPAIGN })
  }
}

function* getAutomationFlows() {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/organisation/content/flows`, {
      organisation_id: organisation.id,
    })

    yield put({
      type: actions.RECEIVE_GET_AUTOMATION_FLOWS,
      data,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_AUTOMATION_FLOWS })
  }
}

function* getCampaign(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/campaign/${action.campaignId}`, {
      organisation_id: organisation.id,
    })

    const adapted = campaignAdapter.fromApi('getCampaign', data)

    yield put({
      type: actions.RECEIVE_GET_CAMPAIGN,
      data: adapted,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_CAMPAIGN })
  }
}

function* getCampaigns() {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/campaign/list`, {
      organisation_id: organisation.id,
    })

    yield put({
      type: actions.RECEIVE_GET_CAMPAIGNS,
      data,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_CAMPAIGNS })
  }
}

function* getOutreaches(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/campaign/record/outreaches`, {
      campaign_id: action.campaignId,
      organisation_id: organisation.id,
      identifiers: {
        thread_id: action.threadId,
      },
    })
    const adapted = campaignAdapter.fromApi('getOutreaches', data)

    yield put({
      type: actions.RECEIVE_GET_OUTREACHES,
      data: adapted,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_OUTREACHES })
  }
}

function* publishCampaign(action) {
  try {
    yield delay(250) // Debounce :-)

    const organisation = getCurrentOrganisation()

    yield call(api.put, `/campaign/publish`, {
      campaign_id: action.campaignId,
      organisation_id: organisation.id,
    })

    yield put({ type: actions.RECEIVE_PUBLISH_CAMPAIGN })
    yield put({
      type: actions.REQUEST_GET_CAMPAIGN,
      campaignId: action.campaignId,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_PUBLISH_CAMPAIGN })
  }
}

function* togglePauseCampaign(action) {
  try {
    yield delay(250) // Debounce :-)

    const organisation = getCurrentOrganisation()

    yield call(api.put, `/campaign/pause`, {
      campaign_id: action.campaignId,
      organisation_id: organisation.id,
    })

    yield put({ type: actions.RECEIVE_TOGGLE_PAUSE_CAMPAIGN })
    yield put({
      type: actions.REQUEST_GET_CAMPAIGN,
      campaignId: action.campaignId,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_TOGGLE_PAUSE_CAMPAIGN })
  }
}

function* setCampaignFlow(action) {
  try {
    const organisation = getCurrentOrganisation()
    const flow = JSON.parse(JSON.stringify(action.flow))

    yield call(api.post, `/campaign/set_flow`, {
      campaign_id: action.campaignId,
      organisation_id: organisation.id,
      ...(action.flow
        ? {
            flow: campaignAdapter.toApi('setCampaignFlow', flow),
          }
        : {}),
      ...(action.template && action.template !== START_BLANK
        ? {
            create_from_template: true,
            flow: action.template,
          }
        : {}),
      ...(action.template && action.template === START_BLANK
        ? {
            flow: null,
          }
        : {}),
    })

    yield put({ type: actions.RECEIVE_SET_CAMPAIGN_FLOW })
    yield put({
      type: NOTIFY,
      notification: {
        type: 'success',
        message: 'Successfully updated automation flow 🔥',
      },
    })
  } catch (error) {
    yield put({ type: actions.FAILED_SET_CAMPAIGN_FLOW, error })
  }
}

function* updateCampaign(action) {
  try {
    yield delay(500) // Debounce :-)

    const organisation = getCurrentOrganisation()

    yield call(api.put, `/campaign/update`, {
      campaign_id: action.campaignId,
      organisation_id: organisation.id,
      settings: {
        ...(action?.name ? { name: action.name } : {}),
        ...(action?.audienceId ? { audience_id: action.audienceId } : {}),
        ...(action.settings ? { advanced: campaignAdapter.toApi('', { ...action.settings }) } : {}),
      },
    })

    yield put({ type: actions.RECEIVE_UPDATE_CAMPAIGN })
    yield put({
      type: actions.REQUEST_GET_CAMPAIGN,
      campaignId: action.campaignId,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_UPDATE_CAMPAIGN })
  }
}

export default function* root() {
  yield takeLatest(actions.REQUEST_CREATE_CAMPAIGN, createCampaign)
  yield takeLatest(actions.REQUEST_DELETE_CAMPAIGN, deleteCampaign)
  yield takeLatest(actions.REQUEST_DUPLICATE_CAMPAIGN, duplicateCampaign)
  yield takeLatest(actions.REQUEST_GET_AUTOMATION_FLOWS, getAutomationFlows)
  yield takeLatest(actions.REQUEST_GET_CAMPAIGN, getCampaign)
  yield takeLatest(actions.REQUEST_GET_CAMPAIGNS, getCampaigns)
  yield takeLatest(actions.REQUEST_GET_OUTREACHES, getOutreaches)
  yield takeLatest(actions.REQUEST_PUBLISH_CAMPAIGN, publishCampaign)
  yield takeLatest(actions.REQUEST_SET_CAMPAIGN_FLOW, setCampaignFlow)
  yield takeLatest(actions.REQUEST_TOGGLE_PAUSE_CAMPAIGN, togglePauseCampaign)
  yield takeLatest(actions.REQUEST_UPDATE_CAMPAIGN, updateCampaign)
}
