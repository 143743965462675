import { call, delay, takeLatest, put } from 'redux-saga/effects'
import { saveAs } from 'file-saver'
import api from 'services/api'
import { PeopleAdapter } from 'adapters/people'
import * as actions from 'redux/actions/people'
import { NOTIFY } from 'redux/actions/notification'
import { getCurrentOrganisation } from 'config/helpers'

const peopleAdapter = new PeopleAdapter()

function* createAudience(action) {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.post, `/audience/`, {
      organisation_id: organisation.id,
      filters: action.filters || [],
      name: action.name,
      generate: action.generate,
      correct_function_only: action.targetSimilarProfiles || false,
      personal_email_only: action.onlyTargetPeople || false,
      ...(action.limit !== false ? { limit: action.limit } : {}),
    })

    yield put({
      type: actions.RECEIVE_CREATE_AUDIENCE,
      data,
    })

    window.location.href = `/people/audience/${data.id}`
  } catch (error) {
    let errorMessage = ''

    switch (error?.response?.status) {
      case 429:
        errorMessage = 'Another Audience is already generating, please try again later.'
        break
      case 404:
        errorMessage = 'The Audience could not be found, please try again later.'
        break
      default:
        errorMessage = 'Something went wrong trying to fill your Audience, please try again later.'
    }

    yield put({
      type: NOTIFY,
      notification: {
        type: 'warning',
        message: errorMessage,
      },
    })

    yield put({
      type: actions.FAILED_CREATE_AUDIENCE,
    })
  }
}

function* deleteAudience({ audienceId, successRedirect }) {
  try {
    const organisation = getCurrentOrganisation()
    const endpoint = [
      `/audience/`,
      `organisation_id=${organisation.id}&audience_id=${audienceId}`,
    ].join('?')

    yield call(api.delete, endpoint)

    yield put({ type: actions.RECEIVE_DELETE_AUDIENCE })

    yield put({
      type: NOTIFY,
      notification: {
        type: 'success',
        message: 'Audience was succesfully deleted.',
      },
    })

    setTimeout(() => {
      if (successRedirect) {
        window.location.href = successRedirect
      }
    }, 2000)
  } catch (error) {
    yield put({
      type: actions.FAILED_DELETE_AUDIENCE,
    })
  }
}

function* getAudience({ audienceId }) {
  try {
    const organisation = getCurrentOrganisation()
    const endpoint = [
      `/audience/`,
      `organisation_id=${organisation.id}&audience_id=${audienceId}`,
    ].join('?')

    const data = yield call(api.get, endpoint)

    yield put({
      type: actions.RECEIVE_GET_AUDIENCE,
      data,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_AUDIENCE })
  }
}

function* generateAudience({ audienceId }) {
  try {
    const endpoint = `/audience/${audienceId}/generate`

    yield call(api.get, endpoint)
    yield call(getAudience, { audienceId })
    yield put({
      type: NOTIFY,
      notification: {
        type: 'success',
        message: 'Audience filling started succesfully.',
      },
    })
  } catch (error) {
    let errorMessage = ''

    switch (error?.response?.status) {
      case 429:
        errorMessage = 'Another Audience is already generating, please try again later.'
        break
      case 404:
        errorMessage = 'The Audience could not be found, please try again later.'
        break
      default:
        errorMessage = 'Something went wrong trying to fill your Audience, please try again later.'
    }

    yield put({
      type: NOTIFY,
      notification: {
        type: 'warning',
        message: errorMessage,
      },
    })

    yield put({ type: actions.FAILED_GENERATE_AUDIENCE })
  }
}

function* downloadTestBatch({ filters, data }) {
  try {
    const organisation = getCurrentOrganisation()
    const endpoint = `/${organisation.id}/audience/explore/testbatch`

    const response = yield call(api.post, endpoint, {
      filters,
      ...data,
    })

    const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' })
    const filename = `${organisation.name}-org-testbatch.csv`
    saveAs(blob, filename)

    yield put({
      type: NOTIFY,
      notification: {
        type: 'success',
        message: 'Testbatch downloaded succesfully.',
      },
    })
  } catch (error) {
    yield put({
      type: NOTIFY,
      notification: {
        type: 'warning',
        message: 'Something went wrong generating the test batch, please try again later.',
      },
    })

    yield put({ type: actions.FAILED_GENERATE_AUDIENCE })
  }
}

function* getAudiences() {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.get, `/audience/?organisation_id=${organisation.id}`)

    yield put({
      type: actions.RECEIVE_GET_AUDIENCES,
      data,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_AUDIENCES })
  }
}

function* getAutoCompleteResults(action) {
  try {
    yield delay(500) // Debounce :-)

    const organisation = getCurrentOrganisation()
    const data = yield call(api.get, `/${organisation.id}/audience/explore/autocomplete`, {
      params: {
        filter_id: action.filterId,
        text: action.text,
      },
    })

    yield put({
      type: actions.RECEIVE_GET_PROSPECT_SAMPLE_AUTOCOMPLETE_RESULTS,
      category: action.category,
      data,
      filterId: action.filterId,
      index: action.index,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_PROSPECT_SAMPLE_AUTOCOMPLETE_RESULTS })
  }
}

function* getBlockList(action) {
  try {
    const organisation = getCurrentOrganisation()
    const endpoint = [
      `/organisation/${organisation.id}/blocklist`,
      `page_number=${action.pageNumber}&page_size=12`,
    ].join('?')

    const { data, total_count } = yield call(api.get, endpoint)

    yield put({
      type: actions.RECEIVE_GET_BLOCK_LIST,
      data: peopleAdapter.fromApi('', data),
      pageNumber: action.pageNumber,
      totalCount: total_count,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_BLOCK_LIST })
  }
}

function* getLeads(action) {
  try {
    const organisation = getCurrentOrganisation()
    const endpoint = [
      `/organisation/${organisation.id}/leads`,
      [
        `page_number=${action.pageNumber}`,
        `page_size=10`,
        ...(action.audienceId ? [`audience_id=${action.audienceId}`] : []),
      ].join('&'),
    ].join('?')

    const { data, total_count } = yield call(api.get, endpoint)

    yield put({
      type: actions.RECEIVE_GET_LEADS,
      data: peopleAdapter.fromApi('', data),
      pageNumber: action.pageNumber,
      totalCount: total_count,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_LEADS })
  }
}

function* getProspects(action) {
  try {
    const organisation = getCurrentOrganisation()
    const endpoint = [
      `/organisation/${organisation.id}/prospects`,
      [
        `page_number=${action.pageNumber}`,
        `page_size=10`,
        ...(action.audienceId ? [`audience_id=${action.audienceId}`] : []),
        ...(action.responded ? [`responded=true`] : []),
      ].join('&'),
    ].join('?')

    const { data, total_count } = yield call(api.get, endpoint)

    yield put({
      type: actions.RECEIVE_GET_PROSPECTS,
      data: peopleAdapter.fromApi('', data),
      pageNumber: action.pageNumber,
      totalCount: total_count,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_LEADS })
  }
}

function* getProspectSampleFilters() {
  try {
    const organisation = getCurrentOrganisation()
    const data = yield call(api.get, `/${organisation.id}/audience/explore/filters`)

    yield put({
      type: actions.RECEIVE_GET_PROSPECT_SAMPLE_FILTERS,
      data,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_PROSPECT_SAMPLE_FILTERS })
  }
}

function* getProspectSample(action) {
  try {
    yield delay(500) // Debounce :-)
    const organisation = getCurrentOrganisation()
    const { data } = yield call(api.post, `/${organisation.id}/audience/explore`, {
      filters: action.filters,
      correct_function_only: action.targetSimmilarProfiles || false,
      personal_email_only: action.onlyTargetPeople || false,
    })

    yield put({
      type: actions.RECEIVE_GET_PROSPECT_SAMPLE,
      data: peopleAdapter.fromApi('', data),
    })

    const { count } = yield call(api.post, `/${organisation.id}/audience/explore/count`, {
      filters: action.filters,
      correct_function_only: action.targetSimmilarProfiles || false,
      personal_email_only: action.onlyTargetPeople || false,
    })

    yield put({
      type: actions.RECEIVE_GET_PROSPECT_SAMPLE_COUNT,
      count: count?.count,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_PROSPECT_SAMPLE })
  }
}

function* moveProspect(action) {
  try {
    const organisation = getCurrentOrganisation()
    let endpoint = `/organisation/${organisation.id}`
    let successAction

    if (action.toList === 'leads') {
      endpoint += `/blocklist/${action.prospectId}/to_leads`
      successAction = {
        type: actions.REQUEST_GET_BLOCK_LIST,
        pageNumber: action.currentPage,
      }
    } else if (action.toList === 'blocklist') {
      endpoint += `/leads/${action.prospectId}/to_blocklist`
      successAction = {
        type: actions.REQUEST_GET_LEADS,
        pageNumber: action.currentPage,
      }
    } else {
      throw new `Unsupported list: ${action.toList}`()
    }

    yield call(api.put, endpoint)

    yield put({ type: actions.RECEIVE_MOVE_PROSPECT })

    yield put({
      ...successAction,
      organisationId: organisation.id,
    })
  } catch (error) {
    console.log(error)
    yield put({ type: actions.FAILED_MOVE_PROSPECT })
  }
}

export default function* root() {
  yield takeLatest(actions.REQUEST_CREATE_AUDIENCE, createAudience)
  yield takeLatest(actions.REQUEST_DELETE_AUDIENCE, deleteAudience)
  yield takeLatest(actions.REQUEST_GET_AUDIENCE, getAudience)
  yield takeLatest(actions.REQUEST_GENERATE_AUDIENCE, generateAudience)
  yield takeLatest(actions.REQUEST_DOWNLOAD_TESTBATCH, downloadTestBatch)
  yield takeLatest(actions.REQUEST_GET_AUDIENCES, getAudiences)
  yield takeLatest(actions.REQUEST_GET_BLOCK_LIST, getBlockList)
  yield takeLatest(actions.REQUEST_GET_PROSPECT_SAMPLE, getProspectSample)
  yield takeLatest(actions.REQUEST_GET_PROSPECT_SAMPLE_AUTOCOMPLETE_RESULTS, getAutoCompleteResults)
  yield takeLatest(actions.REQUEST_GET_PROSPECT_SAMPLE_FILTERS, getProspectSampleFilters)
  yield takeLatest(actions.REQUEST_GET_PROSPECTS, getProspects)
  yield takeLatest(actions.REQUEST_GET_LEADS, getLeads)
  yield takeLatest(actions.REQUEST_MOVE_PROSPECT, moveProspect)
}
