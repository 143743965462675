import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { REQUEST_USER_AUTH_STATUS } from 'redux/actions/user'
// Import Routes component
import Routes from 'ui/components/Routes'

// Map state to props
const mapStateToProps = (state, ownProps = {}) => ({
  ...state.user,
})

// Map dispatch to props
const mapDispatchToProps = (dispatch, ownProps = {}) => ({
  userAuthStatus: () => dispatch({ type: REQUEST_USER_AUTH_STATUS }),
})

// App container must have withRouter
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
